import type { ApiErrorResponse } from "@warrenio/api-spec/spec.oats.gen";
import { HttpResponse, type StrictResponse } from "msw";
import { loadForeverType, mockHeader } from "../utils/fetchClient.mock.ts";

export function standardErrorResponse(errorMessage: string, status = 500): StrictResponse<ApiErrorResponse> {
    const error: ApiErrorResponse = { errors: { Error: errorMessage } };
    return HttpResponse.json(error, { status, statusText: "Internal server error" });
}

/**
 * This response will be handled by the fetch client.
 * It will return a promise that never resolves, simulating a request that never completes.
 *
 * The simulation is not done on the MSW side to prevent confusing Chromatic.
 */
export function loadForeverResponse() {
    return HttpResponse.json("Loading forever", {
        status: 200,
        statusText: "Loading forever",
        headers: { [mockHeader]: loadForeverType },
    });
}
