/** A lock is just a function that runs the {@link fn} argument within the lock. */
export type PromiseLock = <T>(fn: () => Promise<T>) => Promise<T>;

/** A lock that only allows a single async function to run at a time. */
export function makePromiseLock(): PromiseLock {
    let currentPromise: Promise<unknown> | null = null;

    return async function runWithLock<T>(fn: () => Promise<T>): Promise<T> {
        // Build a chain of promises that run one after the other.
        const newPromise = (async () => {
            try {
                await currentPromise;
            } catch {
                // If the current promise was rejected, we still want to run the new one.
            }
            return await fn();
        })();

        currentPromise = newPromise;
        try {
            return await newPromise;
        } finally {
            // Prevent memory leaks
            if (currentPromise === newPromise) {
                currentPromise = null;
            }
        }
    };
}

/** A no-op lock type does not actually do anything. */
export function makeNoopPromiseLock(): PromiseLock {
    return async function runWithLock<T>(fn: () => Promise<T>): Promise<T> {
        return await fn();
    };
}
