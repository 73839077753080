import { objectFromKeys } from "@warrenio/utils/collections/objs";
import { makeNoopPromiseLock, makePromiseLock } from "@warrenio/utils/promise/promiseLock";
import sleep from "@warrenio/utils/promise/sleep";
import { HttpResponse, http as mswHttp, type DefaultBodyType, type HttpResponseResolver, type PathParams } from "msw";
import invariant from "tiny-invariant";
import * as debug from "../../debugConfig.ts";
import { objectHash } from "../../utils/hash.ts";
import { withGlobalSeed } from "../../utils/random.ts";
import { getMockDb } from "../db.ts";
import { mockApiActiveHeader } from "../ids.ts";
import { randomFloatBetween, randomLowerAlpha, randomLowerAlphaNumeric } from "../randomTypes.ts";
import type { StandardRequest } from "./StandardRequest.ts";
import { countRequest, matchRequest } from "./mockRequestMatcher.ts";
import { getResponseFromError } from "./responseError.ts";

//#region Globals
export const apiPrefix = "/v1/";

const requestLock = debug.requestsInSerial ? makePromiseLock() : makeNoopPromiseLock();
//#endregion

//#region Handlers factory

type StandardResponse = Response | undefined;

export interface HandlerOptions {
    /** Do not use standard pre/post-request processing. */
    raw?: boolean;
}

const methods = ["all", "head", "get", "post", "put", "delete", "patch", "options"] as const;

/** Wrapper for MSW handler factories that does standard pre/post-request processing. */
export const http = objectFromKeys(
    methods,
    (method) =>
        <TResponse extends DefaultBodyType = undefined>(
            path: string,
            handler: HttpResponseResolver<PathParams<string>, DefaultBodyType, TResponse>,
            options?: HandlerOptions,
        ) => {
            invariant(path.startsWith("/"), `Path must start with /: ${path}`);
            // Automatically prepend the API prefix
            const mswPath = `${apiPrefix}${openApiToMswPath(path.slice(1))}`;

            return mswHttp[method](mswPath, async function requestWrapper(r, ...args): Promise<StandardResponse> {
                type HandlerRequest = Parameters<typeof handler>[0];

                async function callOriginal() {
                    // WORKAROUND: use `as` – too many generic type arguments to handle the request type nicely in TypeScript
                    return (await handler(r as HandlerRequest, ...args)) as StandardResponse;
                }

                countRequest(r);

                if (options?.raw) {
                    return await callOriginal();
                }

                const result = await requestLock(async () => await processRequest(r, callOriginal));

                if (result instanceof Response) {
                    // Client uses this header to detect if the mock API is alive
                    result.headers.set(mockApiActiveHeader, "1");

                    result.headers.set("x-warren-correlation-id", generateCorrelationId(r));
                }

                // eslint-disable-next-line @typescript-eslint/return-await -- ESLint bug
                return result;
            });
        },
);

/** Convert from standard OpenAPI `{param}` format to MSW `:param` format. */
function openApiToMswPath(openApiPath: string): string {
    return openApiPath.replace(/{([^}]+)}/g, ":$1");
}

//#endregion

/** Run standard request "middleware". */
async function processRequest(r: StandardRequest, callOriginal: () => Promise<StandardResponse>) {
    // Apply mock delay
    await delayRequest(r);

    // Check if we have a mock response
    const mockResponse = await getMockResponse(r);
    if (mockResponse !== undefined) {
        return mockResponse;
    }

    try {
        return await callOriginal();
    } catch (e) {
        // Convert errors to responses
        const response = getResponseFromError(e);
        if (response) {
            console.error("Mock API: sending error response", e);
            return response;
        }

        if (!(e instanceof Error)) {
            throw e;
        }

        console.error("Mock API: unhandled exception", e);
        return HttpResponse.json(
            { message: e.message, stack: e.stack },
            { status: 500, statusText: "Unhandled mock handler exception" },
        );
    }
}

async function getMockResponse(r: StandardRequest) {
    const mock = matchRequest(r);
    if (mock !== undefined) {
        console.debug("Mocking request: %o (%s)", r.request.url, r.request.method);
        return await mock;
    }

    switch (getMockDb().options.variant) {
        case "unauthorized": {
            const message = "Invalid authentication credentials";
            return HttpResponse.json({ message }, { status: 403 });
        }
    }
}

async function delayRequest(r: StandardRequest) {
    const method = r.request.method.toUpperCase();
    const delay = method === "GET" ? debug.requestDelay : debug.mutationRequestDelay;
    if (delay) {
        const randomDelay = randomFloatBetween(delay * 0.6, delay * 1.3);
        await sleep(randomDelay);
    }
}

function generateCorrelationId(r: StandardRequest): string {
    const seed = objectHash(`${r.countKey}:${r.count}`);
    // Use a deterministic seed to ensure the same correlation ID for the same request in stories
    // (which affects Chromatic snapshot rendering of eg. error blocks)
    return withGlobalSeed(seed, () => `${randomLowerAlpha(3)}-${randomLowerAlphaNumeric()}`);
}
