import { HOURS, MINUTES } from "@warrenio/utils/timeUnits";
import { isStorybook } from "../utils/environment.ts";
import { toTzString } from "./randomTypes.ts";

/** Current time in tests */
const mockTimeBase = new Date("2024-01-02T13:45:56Z");
const mockTimeAdvance = 1 * HOURS + 1 * MINUTES;
let mockTimeOffset = 0;

/** Get the current time for tests. Starts from a fixed point in the past and advances by {@link mockTimeAdvance} each time.
 *
 * Required to have a consistent time shown in the UI for snapshot tests.
 */
export function getMockTime(): Date {
    if (isStorybook) {
        const time = new Date(mockTimeBase.getTime() + mockTimeOffset);
        mockTimeOffset += mockTimeAdvance;
        return time;
    } else {
        return new Date();
    }
}

export function getMockTimeTz() {
    return toTzString(getMockTime());
}

export function getMockTimeEpoch() {
    return Math.floor(getMockTime().getTime() / 1000);
}

export function resetMockTime() {
    mockTimeOffset = 0;
}

function dateToIsoString(date: Date) {
    return date.toISOString().replace(/Z$/, "+0000");
}

/** @returns "2024-01-01T13:45:56.789+0000" */
export function getMockTimeIso() {
    return dateToIsoString(getMockTime());
}
