import { isObject } from "@warrenio/utils/isObject";

const ERROR_RESPONSE_PROPERTY = Symbol.for("handler/errorResponse");

interface ErrorResponseProperty {
    [ERROR_RESPONSE_PROPERTY]: Response;
}

/** Decorate an arbitrary error with an HTTP response */
export function addResponseToError<T extends Error>(e: T, response: Response): T & ErrorResponseProperty {
    Object.defineProperty(e, ERROR_RESPONSE_PROPERTY, { value: response, enumerable: false });
    return e as T & ErrorResponseProperty;
}

/** Get the response from a (decorated) error, if it has one */
export function getResponseFromError(e: unknown): Response | undefined {
    if (isObject(e) && ERROR_RESPONSE_PROPERTY in e) {
        return (e as ErrorResponseProperty)[ERROR_RESPONSE_PROPERTY];
    }

    return undefined;
}

/** An exception that also carries an HTTP response */
export class ErrorWithResponse extends Error implements ErrorResponseProperty {
    name = "ErrorWithResponse";
    [ERROR_RESPONSE_PROPERTY]: Response;

    constructor(response: Response, message?: string) {
        super(message ?? response.statusText);
        this[ERROR_RESPONSE_PROPERTY] = response;
    }
}
