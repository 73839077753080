export function joinPath(path: string, ...paths: string[]) {
    let result = path;
    for (const p of paths) {
        if (result.endsWith("/")) {
            result += p;
        } else {
            result += `/${p}`;
        }
    }
    return result;
}
