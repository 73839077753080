import { joinPath } from "@warrenio/utils/joinPath";
import { setupWorker, type SetupWorker } from "msw/browser";
import * as _handlers from "../handlers.ts";

// Mutable alias for hot reload
let handlers = _handlers;

function matchesApiPrefix(url: string) {
    if (!url.includes(handlers.apiPrefix)) {
        return false;
    }

    const p = new URL(url);
    if (!p.pathname.startsWith(handlers.apiPrefix)) {
        return false;
    }

    return true;
}

let worker: SetupWorker | undefined;

/** @param enabled If Mock Service Worker should be running. */
export async function registerMsw(enabled: boolean) {
    if (!enabled) {
        if (worker) {
            console.info("Stopping mock service worker");
            worker.stop();
            worker = undefined;
        }
        return;
    }

    if (worker) {
        console.info("Mock service worker already running");
        return;
    }

    const url = joinPath(import.meta.env.BASE_URL, "mockServiceWorker.js");

    console.info("Registering mock service worker: %s", url);

    worker = setupWorker(...handlers.handlers);

    worker.events.on("unhandledException", (error) => {
        console.error("Unhandled exception in MSW", error);
    });

    const reg = await worker.start({
        serviceWorker: {
            options: {
                scope: "/",
            },

            url,
        },
        // quiet: true,
        onUnhandledRequest(request, print) {
            if (!matchesApiPrefix(request.url)) {
                // console.debug("Ignoring request to", request.url);
                return;
            }

            // Fail the request (safer than bypassing it)
            print.error();
        },
    });
    return reg;
}

// Support hot reloading the handlers when they change.

import.meta.hot?.accept(["../handlers.ts"], ([newHandlers]: unknown[]) => {
    handlers = newHandlers as typeof _handlers;
    if (worker) {
        console.debug("Hot reloading mock MSW handlers");
        worker.resetHandlers(...handlers.handlers);
    }
});
